import React from 'react';
import Check from 'svg/Check';
import { BENEFITS } from './constants';

function RemoteCoachingSection() {
  return (
    <section className="relative mx-auto px-4 pt-16 xs:mb-5 xs:mt-10 md:mt-24">
      <h2 className="typography-informative-display-secondary-mobile max-md:typography-informative-subheading-medium text-center">
        <mark className="max-md:typography-informative-subheading-medium typography-informative-display-primary-mobile bg-transparent">
          Remote
        </mark>{' '}
        coaching
      </h2>
      <p className="xs:typography-informative-caption md:typography-informative-subheading mt-3 text-center text-color-text-lightmode-secondary dark:text-color-text-lightmode-secondary">
        Choose a coach, upload footage and get tailored guidance sent back to you.
      </p>
      <div className="mx-auto mt-8 flex gap-10 sm:container max-sm:mt-6 max-sm:flex-col-reverse max-sm:items-center">
        <div className="flex justify-center sm:basis-[55%]">
          <img alt="grouped-remote" src="/images/players/stacked-phones.png" />
        </div>
        <ul className="flex flex-col gap-11 max-md:gap-4 max-md:pt-10 max-sm:pt-0 md:pt-20">
          {BENEFITS.map((benefit, index) => (
            <li key={index} className="flex items-center xs:gap-3 md:gap-2">
              <div className="flex items-center justify-center rounded-full bg-color-bg-lightmode-primary p-2 shadow focus:outline-none focus:ring-2 focus:ring-color-brand-primary dark:bg-color-bg-darkmode-primary">
                <Check className="h-5 w-5 text-color-text-brand dark:text-color-text-brand" />
              </div>
              <span className="xs:typography-informative-body-superhighlight md:typography-informative-quote text-left">
                {benefit}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default RemoteCoachingSection;
