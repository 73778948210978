import { TOURNAMENTS_PAGE, TOURNAMENT_QUICK_CREATE_PAGE } from 'constants/pages';
import ArrowRight from 'svg/ArrowRight';
import { ButtonLink } from 'components/Button';
import { GradientBackground } from './utils';

const RoundRobinSection = () => {
  const footerBtns = (
    <>
      <ButtonLink
        href={TOURNAMENTS_PAGE}
        variant="secondary"
        sizeMobile="sm"
        sizeDesktop="lg"
        className="shrink border-0"
        iconRight={<ArrowRight className="h-4 w-4" />}
      >
        Join a round robin
      </ButtonLink>
      <ButtonLink
        href={TOURNAMENT_QUICK_CREATE_PAGE}
        variant="brand"
        sizeMobile="sm"
        sizeDesktop="lg"
        className="shrink"
        iconRight={<ArrowRight className="h-4 w-4" />}
      >
        Create a round robin
      </ButtonLink>
    </>
  );

  return (
    <section className="relative isolate mt-8 flex flex-row-reverse items-center gap-4 px-20 max-sm:flex-col max-sm:px-4">
      <GradientBackground left="50%" className="max-sm:hidden" />
      <div className="flex-[0.75]">
        <div className="flex max-w-[620px] flex-col  justify-start gap-2 text-center">
          <h2 className="max-md:typography-informative-subheading-medium typography-informative-display-secondary-mobile text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
            Create{' '}
            <mark className="max-md:typography-informative-subheading-medium-highlight typography-informative-display-primary-mobile bg-transparent">
              round robins
            </mark>{' '}
            with ease
          </h2>
          <p className="sm:typography-informative-subheading-compact typography-product-caption text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary">
            Organize round robins and structured open play with your community.
          </p>
          <div className="mt-9 flex gap-2  max-md:flex-col max-sm:hidden md:px-10">
            {footerBtns}
          </div>
        </div>
      </div>
      <img
        src={'/images/players/iphone-14-mockup.png'}
        alt="Bounce round robin"
        className="mx-auto h-auto max-w-80 lg:max-w-96"
        loading="lazy"
      />
      <div className="hidden w-full gap-2 max-sm:flex">{footerBtns}</div>
    </section>
  );
};

export default RoundRobinSection;
