import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import * as z from 'zod';
import { AccountType } from 'constants/user';
import {
  GetActiveOwnerLessonsByIdQuery,
  GetCoachBookingPageQuery,
  GetCoachesByGeoQuery,
  GetGroupsQuery,
} from 'types/generated/client';
import { GetTournamentsForMarketplaceQuery } from 'types/generated/server';
import { RemoteCoachTypesEnum } from 'utils/shared/coach/constants';
import { VenueAddress } from 'utils/shared/coachBuilder';
import { CalendarBlock } from 'components/LessonCalendar/props';
import { lessonAnalysisFormSchema, videoAnalysisFilesSchema } from './utils';

interface AdditionalTournamentDetails {
  startTimestamp: number;
  displayDate: string;
  distance: number;
}

export type TournamentType = GetTournamentsForMarketplaceQuery['events'][0] &
  AdditionalTournamentDetails;

export interface TournamentSectionProps {}

export interface LocationBarProps {}

export type CoachType = (
  | GetCoachesByGeoQuery['coachStarterProfiles'][0]
  | GetCoachesByGeoQuery['userProfiles'][0]
) & { distance?: number; followersAggregate?: any };

export type AddditonalCoachType = {
  href: string;
  onCourtPrice: string | number;
  remotePrice: string | number;
  imageSrc: string;
};

export type CombinedCoachType = CoachType & AddditonalCoachType;

export type OmittedCoachType =
  | Omit<Exclude<GetCoachBookingPageQuery['userProfiles'][0], null | undefined>, '__typename'>
  | GetCoachBookingPageQuery['userProfiles'][0];

export interface BookLessonProps {
  onCourtLessonLoading: boolean;
  remoteLessonLoading: boolean;
  venuesLoading: boolean;
  activeLesson: CombinedCoachType | null;
  activeLocation: VenueAddress | null;
  setActiveLocation: React.Dispatch<React.SetStateAction<VenueAddress | null>>;
  setActiveLesson: React.Dispatch<React.SetStateAction<CombinedCoachType | null>>;
  onCourtLessons: CombinedCoachType[];
  remoteLessons: CombinedCoachType[];
  venues: VenueAddress[];
  coachAvailability: CoachAvailabilityType;
  activeOwnerLessons: ActiveOwnerLessonsType;
  handleSelectSlot: HandleSelectSlotFnType;
  selectedDate: Date | null;
  handleBookLesson: () => void;
  nearbyVenues: VenueAddress[];
  openLoginModal: () => void;
  onlyFollowedCoached?: boolean;
}

export interface PlayerHomeProps {
  isServerPlayer: boolean;
  isServerAnonymous: boolean;
}

export interface BookLessonModalProps {
  isOpen: boolean;
  handleClose: () => void;
  coachAvailability: CoachAvailabilityType;
  activeLocation: VenueAddress | null;
  activeOwnerLessons: ActiveOwnerLessonsType;
  handleSelectSlot: HandleSelectSlotFnType;
  selectedDate: Date | null;
  handleBookLesson: () => void;
}

export interface LocationPickerProps {
  handlePickTime: () => void;
  venues: VenueAddress[];
  setActiveLocation: React.Dispatch<React.SetStateAction<VenueAddress | null>>;
  activeLocation: VenueAddress | null;
  nearbyVenues: VenueAddress[];
  loading: boolean;
}

export interface LessonCardProps {
  isActive: boolean;
  onClick: () => void;
  isMobile?: boolean;
  lesson: CombinedCoachType;
  remoteCoachType: RemoteCoachTypesEnum;
}

export interface GroupSectionProps {
  groups: GetGroupsQuery['groups'];
  loading: boolean;
}

export interface fetchGroupFnProps {
  limit: number;
  latitude: number;
  longitude: number;
  distance: number;
}

export interface SelectCoachStepProps {
  loading: boolean;
  lessons: CombinedCoachType[];
  activeLesson: CombinedCoachType | null;
  handleLessonSelect: (lesson: CombinedCoachType) => void;
  onlyFollowedCoached?: boolean;
  activeTab: LessonBookingTabEnum;
}

export interface ChooseLocationStepProps {
  venues: VenueAddress[];
  venuesLoading: boolean;
  setActiveLocation: React.Dispatch<React.SetStateAction<VenueAddress | null>>;
  activeLocation: VenueAddress | null;
  nearbyVenues: VenueAddress[];
}

export interface LocationCardProps {
  venue: VenueAddress;
  setActiveLocation: React.Dispatch<React.SetStateAction<VenueAddress | null>>;
  activeLocation: VenueAddress | null;
}

export interface ChooseTimeSlotStepProps {
  coachAvailability: CoachAvailabilityType;
  activeOwnerLessons: ActiveOwnerLessonsType;
  handleSelectSlot: HandleSelectSlotFnType;
  selectedDate: Date | null;
  handleBookLesson: () => void;
}

export interface PaymentModalProps {
  isOpen: boolean;
  handleClose: () => void;
  selectedDate: Date | null;
  activeLocation: VenueAddress | null;
  activeLesson: CombinedCoachType | null;
  handleBookAnotherLesson: () => void;
  onPaymentSuccess: () => void;
}

export interface BookingSwitcherProps {
  activeTab: LessonBookingTabEnum;
  setActiveTab: React.Dispatch<React.SetStateAction<LessonBookingTabEnum>>;
}

export interface OnCourtDesktopProps
  extends Omit<
    BookLessonProps,
    'openLoginModal' | 'remoteLessons' | 'remoteLessonLoading' | 'onCourtLessonLoading'
  > {
  activeTab: LessonBookingTabEnum;
  lessonsLoading: boolean;
}

export interface RemoteDesktopProps extends Pick<BookLessonProps, 'remoteLessons'> {
  activeTab: LessonBookingTabEnum;
  openLoginModal: () => void;
  onlyFollowedCoached?: boolean;
  lessonsLoading: boolean;
}

export interface OnCourtMobileProps
  extends Omit<
    BookLessonProps,
    'openLoginModal' | 'remoteLessons' | 'remoteLessonLoading' | 'onCourtLessonLoading'
  > {
  activeTab: LessonBookingTabEnum;
  lessonsLoading: boolean;
}

export interface RemoteMobileProps
  extends Pick<BookLessonProps, 'remoteLessons' | 'setActiveLesson' | 'activeLesson'> {
  activeTab: LessonBookingTabEnum;
  lessonsLoading: boolean;
}

export interface UploadVideoStepProps {
  lessonAnalysisForm: UseFormReturn<LessonAnalyisFormType>;
  openLoginModal: () => void;
}

export interface ConnectionSectionProps {
  handleRegistration: (accountType: AccountType) => void;
}

export interface RemoteAnalysisModalProps {
  open: boolean;
  handleClose: () => void;
  activeLesson: CombinedCoachType | null;
}

export interface ConfirmLessonStepProps {
  activeLesson: CombinedCoachType | null;
  submissionData: LessonAnalyisFormType;
  disableSubmit: boolean;
  clearForm: () => void;
}

export type ActiveOwnerLessonsType = GetActiveOwnerLessonsByIdQuery['lessons'];

export type CoachAvailabilityType =
  GetCoachBookingPageQuery['userProfiles'][0]['coachAvailability'];

export type HandleSelectSlotFnType = (date: Date | null, block: CalendarBlock | null) => void;

export enum LessonBookingTabEnum {
  Remote = 'remote',
  OnCourt = 'onCourt',
}

export type LessonAnalyisFormType = z.infer<typeof lessonAnalysisFormSchema>;

export type VideoUploadType = z.infer<typeof videoAnalysisFilesSchema>;
