import { Controller } from 'react-hook-form';
import {
  FileCategoryEnum,
  FileFormatEnum,
  useInsertVideoAnalysisSubmissionLogMutation,
  useUpdateVideoAnalysisSubmissionLogMutation,
} from 'types/generated/client';
import { useRequestStatus } from 'hooks/useRequestStatus';
import { useUploadVideoFile } from 'hooks/useUploadVideoFile';
import { useViewer } from 'hooks/useViewer';
import { UploadVideoStepProps, VideoUploadType } from 'screens/PlayerHome/props';
import FileUploader, { SelectedFile } from 'components/VideoAnalysis/FileUploader';
import Skills from 'components/VideoAnalysis/Skills';
import { isVideoDurationValid } from 'components/VideoAnalysis/utils';

const UploadVideoStep: React.FC<UploadVideoStepProps> = ({
  lessonAnalysisForm,
  openLoginModal,
}) => {
  const {
    watch,
    control,
    formState: { errors },
  } = lessonAnalysisForm;

  const { userId } = useViewer();

  const { uploadVideoFile, uploadProgress } = useUploadVideoFile();

  const [insertVideoAnalysisSubmissionLogMutation] = useInsertVideoAnalysisSubmissionLogMutation();
  const [updateVideoAnalysisSubmissionLogMutation] = useUpdateVideoAnalysisSubmissionLogMutation();

  const {
    setLoading: setVideoLoading,
    setSuccess: setVideoSuccess,
    setError: setVideoError,
  } = useRequestStatus();

  const skillType = watch('skillType');

  const renderDetailsTextArea = (field: any) => (
    <>
      <textarea
        {...field}
        id="detailed-feedback"
        placeholder="Add description (optional)"
        className="mt-3 h-[80px] w-full rounded-md border-0 bg-brand-gray-50 pl-3 pr-3 font-light text-color-text-lightmode-primary focus:outline-0 dark:text-color-text-darkmode-primary"
      />
      {errors?.details && <p className="mt-2 text-xs text-color-error">{errors.details.message}</p>}
    </>
  );

  const handleRemoveFile = (
    fileToRemove: SelectedFile,
    currentFiles: VideoUploadType[],
    onChange: (...event: any[]) => void,
  ) => {
    const filteredFiles = currentFiles.filter(
      (currentFile) => currentFile.url !== fileToRemove.url,
    );
    onChange(filteredFiles);
  };

  const handleSelectFiles = async (files: File[], onChange: (...event: any[]) => void) => {
    try {
      setVideoLoading();

      const uploadPromises = files.map(async (file) => {
        const isVideo = file.type.startsWith('video/');

        if (isVideo) {
          let insertVideoAnalysisSubmission = await insertVideoAnalysisSubmissionLogMutation({
            variables: {
              userId: userId || null,
              videoName: file?.name,
            },
          });

          const fileId =
            insertVideoAnalysisSubmission?.data?.insertVideoAnalysisSubmissionLogOne?.id;

          const isValidDuration = await isVideoDurationValid(file, 1200); // Custom max seconds (600s = 20 minutes)

          if (!isValidDuration) {
            alert('Video exceeds 20 minutes. Please upload a shorter video.');
            throw new Error('Video exceeds duration limit');
          }

          try {
            const uploadResponse = await uploadVideoFile({ file });

            await updateVideoAnalysisSubmissionLogMutation({
              variables: {
                userId: userId,
                videoName: uploadResponse?.originalFileName || uploadResponse?.fileName,
                videoPath: uploadResponse?.path,
                id: fileId,
              },
            });

            return {
              fileCategory: FileCategoryEnum.Other,
              fileName: uploadResponse?.fileName,
              fileType: uploadResponse?.fileType || 'image/jpeg',
              host: uploadResponse?.host || 'defaultHost',
              originalFileName: uploadResponse?.originalFileName || uploadResponse?.fileName,
              provider: uploadResponse?.provider,
              providerUrl: uploadResponse?.providerUrl,
              url: uploadResponse?.url,
              fileFormat: FileFormatEnum.Video,
              providerId: '',
              userId,
            };
          } catch (error) {
            console.log(error, 23);
            throw error;
          }
        }
      });

      const payloads = (await Promise.all(uploadPromises)) as VideoUploadType[];
      if (!payloads || payloads.length === 0) return;

      onChange([...payloads]);

      setVideoSuccess();
    } catch (error) {
      console.error('Error uploading files:', error);
      setVideoError('');
    }
  };

  const handleBeforeFileSelection = (): boolean => {
    const isLoggedIn = !!userId;
    if (!isLoggedIn) {
      openLoginModal();
    }
    return isLoggedIn;
  };

  return (
    <div className="min-w-0 flex-1 max-lg:basis-[100%]">
      <Controller
        control={control}
        name="videoAnalysisFiles"
        render={({ field: { onChange, value } }) => {
          return (
            <FileUploader
              uploadProgress={uploadProgress}
              multiple={false}
              accept={{ 'video/*': ['.mp4', '.mov'] }}
              styles="rounded-md"
              selectedFiles={value || []}
              beforeFileSelection={handleBeforeFileSelection}
              onRemoveFile={(fileToRemove) => handleRemoveFile(fileToRemove, value, onChange)}
              onSelectFiles={(files) => handleSelectFiles(files, onChange)}
              helperText="MP4, MOV (max. 15 Mins)"
            />
          );
        }}
      />
      <Skills skillType={skillType} control={control} errors={errors} />
      <div className="mt-5">
        <label
          htmlFor="detailed-feedback"
          className="typography-product-subheading text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary"
        >
          Provide the coach with details
        </label>
        <Controller
          name="details"
          control={control}
          render={({ field }) => renderDetailsTextArea(field)}
        />
      </div>
    </div>
  );
};

export default UploadVideoStep;
